<template>
  <div class="teamorder">
    <van-sticky>
      <van-search
        v-model="keywords"
        show-action
        placeholder="请输入搜索关键词"
        @search="onSearch"
        shape="round"
      >
        <template #left>
          <van-icon name="arrow-left" @click="onClickLeft" size="16" />
        </template>
        <template #action>
          <div @click="onSearch">搜索</div>
        </template>
      </van-search>
    </van-sticky>
    <van-notice-bar left-icon="volume-o" text="收益会随着订单取消或售后产生的退款而变化，仅供参考。" />
    <!-- 日期刷选 -->
    <div class="checkdate">
      <van-row>
        <van-col span="8">日期区间：</van-col>
        <van-col span="16">
          <van-row>
            <van-col span="10" @click="showStartDate">
              <div class="data_start">{{startDate}}</div>
            </van-col>
            <van-col span="4" style="color:#E66A6F;">至</van-col>
            <van-col span="10" @click="showEndDate">
              <div class="data_start">{{endDate}}</div>
            </van-col>
          </van-row>
        </van-col>
      </van-row>
    </div>
    <!-- 订单列表 -->
    <van-tabs v-model="teamOrderActive" @click="incomeTabs">
      <van-tab title="所有">
        <div v-if="list.length > 0">
          <div class="list" v-for="(item, index) in list" :key="index">
            <van-cell center>
              <template #title>
                <div class="list_buy_user">
                  <van-image
                    lazy-lod
                    round
                    width="20"
                    height="20"
                    style="min-width: 20px;"
                    :src="item.avatar"
                  >
                    <template v-slot:loading>
                      <van-loading type="spinner" size="20" />
                    </template>
                  </van-image>
                  <div class="van-ellipsis">{{item.nickname}}</div>
                </div>
              </template>
              <template #right-icon>
                <div>{{item.ordersn}}</div>
              </template>
            </van-cell>
            <van-card
              v-for="(val, key) in item.goods"
              :key="key"
              :desc="val.optionname"
              :thumb="val.goodsthumb"
              :title="val.goodstitle"
              :price="val.price"
              :num="val.total"
              lazy-load
            >
            </van-card>
            <van-cell center class="order_status">
              <template #title>
                <div class="status_str">{{item.statusStr}}</div>
              </template>
              <template #default>
                实付：&yen;{{item.price}}<span v-if="parseFloat(item.dispatchprice) > 0">(含运费 &yen;{{item.dispatchprice}})</span>
              </template>
            </van-cell>
            <van-cell center class="order_status">
              <template #title>
                <div class="status_str">{{item.statusApStr}}</div>
              </template>
              <template #default>
                <van-tag plain type="danger">Y：&yen;{{item.iconme}}</van-tag>
                <van-tag
                  plain
                  type="danger"
                  v-if="userinfo.level1_white == 1 && (item.cha_memberid && item.cha_memberid == userinfo.id)"
                >
                  C ({{item.cha_agent_code ? item.cha_agent_code : ''}})：&yen;{{item.cha}}
                </van-tag>
              </template>
            </van-cell>
          </div>
        </div>
        <div v-if="nodata">
          <van-empty description="暂无订单" />
        </div> 
      </van-tab>
      <van-tab title="待付款">
        <div v-if="list.length > 0 ? true : false">
          <div class="list" v-for="(item, index) in list" :key="index">
            <van-cell center>
              <template #title>
                <div class="list_buy_user">
                  <van-image
                    lazy-lod
                    round
                    width="20"
                    height="20"
                    style="min-width: 20px;"
                    :src="item.avatar"
                  >
                    <template v-slot:loading>
                      <van-loading type="spinner" size="20" />
                    </template>
                  </van-image>
                  <div class="van-ellipsis">{{item.nickname}}</div>
                </div>
              </template>
              <template #right-icon>
                <div>{{item.ordersn}}</div>
              </template>
            </van-cell>
            <van-card
              v-for="(val, key) in item.goods"
              :key="key"
              :desc="val.optionname"
              :thumb="val.goodsthumb"
              :title="val.goodstitle"
              :price="val.price"
              :num="val.total"
              lazy-load
            >
            </van-card>
            <van-cell center class="order_status">
              <template #title>
                <div class="status_str">{{item.statusStr}}</div>
              </template>
              <template #default>
                实付：&yen;{{item.price}}<span v-if="parseFloat(item.dispatchprice) > 0">(含运费 &yen;{{item.dispatchprice}})</span>
              </template>
            </van-cell>
            <van-cell center class="order_status">
              <template #title>
                <div class="status_str">{{item.statusApStr}}</div>
              </template>
              <template #default>
                <van-tag plain type="danger">Y：&yen;{{item.iconme}}</van-tag>
                <van-tag
                  plain
                  type="danger"
                  v-if="userinfo.level1_white == 1 && (item.cha_memberid && item.cha_memberid == userinfo.id)"
                >
                  C ({{item.cha_agent_code ? item.cha_agent_code : ''}})：&yen;{{item.cha}}
                </van-tag>
              </template>
            </van-cell>
          </div>
        </div>
        <div v-if="nodata">
          <van-empty description="暂无订单" />
        </div>
      </van-tab>
      <van-tab title="已付款">
        <div v-if="list.length > 0 ? true : false">
          <div class="list" v-for="(item, index) in list" :key="index">
            <van-cell center>
              <template #title>
                <div class="list_buy_user">
                  <van-image
                    lazy-lod
                    round
                    width="20"
                    height="20"
                    style="min-width: 20px;"
                    :src="item.avatar"
                  >
                    <template v-slot:loading>
                      <van-loading type="spinner" size="20" />
                    </template>
                  </van-image>
                  <div class="van-ellipsis">{{item.nickname}}</div>
                </div>
              </template>
              <template #right-icon>
                <div>{{item.ordersn}}</div>
              </template>
            </van-cell>
            <van-card
              v-for="(val, key) in item.goods"
              :key="key"
              :desc="val.optionname"
              :thumb="val.goodsthumb"
              :title="val.goodstitle"
              :price="val.price"
              :num="val.total"
              lazy-load
            >
            </van-card>
            <van-cell center class="order_status">
              <template #title>
                <div class="status_str">{{item.statusStr}}</div>
              </template>
              <template #default>
                实付：&yen;{{item.price}}<span v-if="parseFloat(item.dispatchprice) > 0">(含运费 &yen;{{item.dispatchprice}})</span>
              </template>
            </van-cell>
            <van-cell center class="order_status">
              <template #title>
                <div class="status_str">{{item.statusApStr}}</div>
              </template>
              <template #default>
                <van-tag plain type="danger">Y：&yen;{{item.iconme}}</van-tag>
                <van-tag
                  plain
                  type="danger"
                  v-if="userinfo.level1_white == 1 && (item.cha_memberid && item.cha_memberid == userinfo.id)"
                >
                  C ({{item.cha_agent_code ? item.cha_agent_code : ''}})：&yen;{{item.cha}}
                </van-tag>
              </template>
            </van-cell>
          </div>
        </div>
        <div v-if="nodata">
          <van-empty description="暂无订单" />
        </div>
      </van-tab>
      <van-tab title="已完成">
        <div v-if="list.length > 0 ? true : false">
          <div class="list" v-for="(item, index) in list" :key="index">
            <van-cell center>
              <template #title>
                <div class="list_buy_user">
                  <van-image
                    lazy-lod
                    round
                    width="20"
                    height="20"
                    style="min-width: 20px;"
                    :src="item.avatar"
                  >
                    <template v-slot:loading>
                      <van-loading type="spinner" size="20" />
                    </template>
                  </van-image>
                  <div class="van-ellipsis">{{item.nickname}}</div>
                </div>
              </template>
              <template #right-icon>
                <div>{{item.ordersn}}</div>
              </template>
            </van-cell>
            <van-card
              v-for="(val, key) in item.goods"
              :key="key"
              :desc="val.optionname"
              :thumb="val.goodsthumb"
              :title="val.goodstitle"
              :price="val.price"
              :num="val.total"
              lazy-load
            >
            </van-card>
            <van-cell center class="order_status">
              <template #title>
                <div class="status_str">{{item.statusStr}}</div>
              </template>
              <template #default>
                实付：&yen;{{item.price}}<span v-if="parseFloat(item.dispatchprice) > 0">(含运费 &yen;{{item.dispatchprice}})</span>
              </template>
            </van-cell>
            <van-cell center class="order_status">
              <template #title>
                <div class="status_str">{{item.statusApStr}}</div>
              </template>
              <template #default>
                <van-tag plain type="danger">Y：&yen;{{item.iconme}}</van-tag>
                <van-tag
                  plain
                  type="danger"
                  v-if="userinfo.level1_white == 1 && (item.cha_memberid && item.cha_memberid == userinfo.id)"
                >
                  C ({{item.cha_agent_code ? item.cha_agent_code : ''}})：&yen;{{item.cha}}
                </van-tag>
              </template>
            </van-cell>
          </div>
        </div>
        <div v-if="nodata">
          <van-empty description="暂无订单" />
        </div>
      </van-tab>
    </van-tabs>
    <van-calendar v-model="dateShow" :min-date="minDate" :max-date="maxDate" :show-confirm="false" @confirm="onConfirm" />
    <!-- 加载中提示 -->
    <div id="loading" v-show="loadshow">
      <van-loading size="24px" type="spinner" vertical>加载中...</van-loading>
    </div>
  </div>
</template>
<script>
export default {
  name: "TeamOrder",
  filters: {
    currencyFormat: function(num) {
      if (num === 0) {
        return "0.00";
      } else {
        num = num + "";
        if (num.length == 1) {
          num = "00" + num;
        }
        if (num.length == 2) {
          num = "0" + num;
        }
        let start = 0;
        start = num.length - 2;
        return num.slice(0, start) + "." + num.slice(start);
      }
    },
    dateFormat: function(value) {
      let date = null;
      let y = null;
      date = new Date(value);
      y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      /*
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      */
      return y + "-" + MM + "-" + d; // + ' ' + h + ':' + m + ':' + s;
    }
  },
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      teamOrderActive: 0,
      startDate: new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-01", // 开始日期
      endDate: new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date(new Date().getFullYear(), new Date().getMonth()+1, 0).getDate(), // 结束日期
      dateShow: false,
      dateShowType: 1, // 1 开始时间，2 结束时间
      minDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1), // 自定义最小可选择日期
      maxDate: new Date(new Date().getFullYear(), new Date().getMonth()+1, 0), // 自定义最大可选择日期
      currSize: 20,
      currOffset: [0, 0, 0, 0],
      teamOrderList: [[], [], [], []], // 列表
      list: [], // 列表
      currLoading: [false, false, false, false],
      currFinished: [false, false, false, false],
      shareid: 0,
      merchid: 0,
      loadshow: false,
      nodata: false,
      userinfo: {},
      keywords: "", // 搜索关键词
    }
  },
  mounted() {
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
    this.$parent.getmerchname(this.merchid, "推广订单");

    this.init();
  },
  methods: {
    init() {
      console.log("sss");
      // 获取订单数据
      this.getTeamOrder()
      // 获取用户信息
      this.getinfo();
    },
    // 获取用户信息
    getinfo() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      _that.$axios
        .post(_that.$store.state.domain + "web/user/getinfor")
        .then(function(response) {
          if (response.data) {
            _that.userinfo = response.data;
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 返回上一页
    onClickLeft(){
      this.$router.go(-1);
    },
    //切换标签
    incomeTabs(name, title) {
      console.log(name, title);
      this.teamOrderActive = name;
      this.list = this.teamOrderList[name];
      if (this.currOffset[name] <= 0 && !this.currFinished[this.teamOrderActive]) {
        console.log('sss');
        this.list = [];
        this.getTeamOrder();
      }else{
        if (this.list.length > 0) {
          this.nodata = false;
        }else{
          this.nodata = true;
        }
        
      }
    },
    // 获取团队订单
    getTeamOrder() {
      let _that = null;
      _that = this;
      _that.loadshow = true;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      _that.$axios
        .post(
          _that.$store.state.domain + "web/commission/myteam_order2",
          _that.$qs.stringify({
            status: _that.teamOrderActive,
            offset: _that.currOffset[_that.teamOrderActive],
            size: _that.currSize,
            startdate: _that.startDate,
            enddate: _that.endDate,
            keywords: _that.keywords
          })
        )
        .then(response => {
          console.log(response);
          _that.currLoading[_that.teamOrderActive] = false; // 订单数据已加载好
          _that.loadshow = false;
          if (response.data.code == 100000) {
            if (parseInt(response.data.data.agenttime) > 0) {
              let agenttime = 0;
              agenttime = parseInt(response.data.data.agenttime) * 1000;
              _that.minDate = new Date(new Date(agenttime).getFullYear(), new Date(agenttime).getMonth(), new Date(agenttime).getDate());
              console.log(_that.minDate);
            }
            if (response.data.data.list.length > 0) {
              _that.nodata = false;
              response.data.data.list.forEach(item =>{
                _that.list.push(item);
                _that.teamOrderList[_that.teamOrderActive].push(item);
              })
            }else{
              _that.nodata = true;
            }
            if (response.data.data.list.length >= _that.currSize) {
              _that.currOffset[_that.teamOrderActive] += _that.currSize;
            }else {
              _that.currFinished[_that.teamOrderActive] = true; // 订单数据加载完成
              _that.currOffset[_that.teamOrderActive] += response.data.data.length;
            }
          }else {
            _that.currFinished[_that.teamOrderActive] = true; // 订单数据加载完成
            _that.nodata = true;
          }
        })
        .catch(error => {
          console.log(error);
          _that.currFinished[_that.teamOrderActive] = true; // 订单数据加载完成
        })
    },
    // 开始时间
    showStartDate() {
      this.dateShowType = 1;
      this.dateShow = true;
    },
    // 结束时间
    showEndDate() {
      this.dateShowType = 2;
      this.dateShow = true;
    },
    // 选择好日期
    onConfirm(date) {
      this.dateShow = false;
      let year = null;
      let month = null;
      let day = null;
      year = date.getFullYear().toString();
      month = date.getMonth() + 1;
      day = date.getDate();
      day = parseInt(day) < 10 ? "0" + day.toString() : day.toString();
      month = parseInt(month) < 10 ? "0" + month.toString() : month.toString();
      if (this.dateShowType == 1) {
        this.startDate = year + "-" + month + "-" + day;
      } else {
        this.endDate = year + "-" + month + "-" + day;
        this.list = [];
        this.getTeamOrder();
      }
      console.log(year, month, day);
    },
    // 执行搜索
    onSearch() {
      this.teamOrderActive = 0;
      this.currOffset[0] = 0;
      this.list = [];
      this.teamOrderList[this.teamOrderActive] = [];
      this.getTeamOrder();
    },
  }
}
</script>
<style lang="less">
.teamorder{
  position: relative;
  z-index: 1;
  background-color: #ededed;
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  .van-search {
    padding: 10px 0;
    overflow: hidden;
    .van-icon {
      height: 24px;
      line-height: 24px;
      padding: 0 16px;
    }
    .van-search__action {
      padding: 0 16px;
    }
    .van-cell .van-cell__value {
      flex: 1;
    }
    .van-field__left-icon .van-icon {
      padding: 0;
    }
  }
  /* 日期筛选样式 */
  .checkdate {
    padding: 0 10px;
    line-height: 44px;
    font-size: 14px;
    background-color: #ededed;
    .data_start {
      display: inline-block;
      margin: 0 auto;
      border-bottom: 2px solid #333;
    }
  }
  .list{
    background-color: #fff;
    overflow: hidden;
    margin: 10px;
    border-radius: 10px;
    text-align: left;
    padding-top: 10px;
    .van-cell {
      padding: 2px 16px;
      overflow: hidden;
      .van-cell__title {
        flex: 1;
        overflow: hidden;
        .list_buy_user {
          margin-right: 5px;
          overflow: hidden;
          display: flex;
          align-items: center;
          .van-image {
            margin-right: 5px;
          }
        }
        
      }
    }
    .van-card{
      background-color: #fff;
      padding: 10px;
      .van-card__thumb{
        img{
          border-radius: 4px;
        }
      }
    }
    .van-card:not(:first-child){
      margin-top: 0;
    }
    .order_status {
      padding: 10px 16px;
      .van-cell__value{
        flex: 1;
        text-align: right;
        .van-tag {
          margin-left: 10px;
        }
      }
      .status_str {
        color: #EE0A24;
      }
    }
  }
  // 加载中
  #loading {
    background-color: rgba(0, 0, 0, 0);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    .van-loading {
      position: absolute;
      left: 50%;
      top: 50%;
      margin: -50px 0 0 -50px;
      padding: 20px;
      border-radius: 6px;
      background-color: rgba(0, 0, 0, 0.5);
      color: #fff;
      .van-loading__text{
        color: #fff;
      }
    }
  }
}
</style>